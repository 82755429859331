var url = window.location.origin + "/images/";

export const IMAGE = {
  logo: url + "YSF_logo.png",
  google_plus: url + "google-plus.png",
  Document: url + "Document.png",
  Group: url + "Group.png",
  Union: url + "Union.png",
  Union_1: url + "Union-1.png",
  Subtract: url + "Subtract.png",
  Vector: url + "Vector.png",
  happy: url + "happy.png",
  Add: url + "Add.png",
  fav_icon: url + "fav-icon.png",
  context_card_img: url + "context-card-img.png",
  qa_icon: url + "qa-icon.png",
  upload_icon: url + "upload-icon.png",
  Delete: url + "Delete.png",
  check_icon: url + "check-icon.png",
  YSF_generate_icon: url + "YSF_generate_icon.png",
  union_icon: url + "union-icon.png",
  x_button: url + "x_button.png",
  del_img: url + "del_img.png",
  prepear_icon: url + "prepear-icon.png",
  close_icon: url + "close_icon.png",
  slider_one: url + "cv.gif",
  slider_two: url + "interview.gif",
  google_icon: url + "google_icon.png",
  generate_icon: url + "generate_icon.png",
  generate_loader_img: url + "generate-loader-img.png",
  Supergroup: url + "Supergroup.png",
  Message_Align_Right: url + "Message_Align_Right.png",
  toggle_icon: url + "toggle_icon.png",
  add_white: url + "add_white.png",
  all_icon: url + "all_icon.png",
  interview_icon: url + "interview_icon.png",
  logo_svg: url + "logo-svg.svg",
  onboard_one: url + "onboard_one.png",
  onboard_two: url + "onboard_two.png",
  onboard_three: url + "onboard_three.png",
  onboard_four: url + "onboard_four.png",
  startup: url + "startup.png",
  wallet: url + "wallet.png",
  refer_img: url + "refer_img.png",
  check: url + "check.png",
  remove: url + "remove.png",
  bill: url + "bill.png",

  gif_1: url + "gif/1.gif",
  gif_2: url + "gif/2.gif",
  gif_3: url + "gif/3.gif",
  gif_4: url + "gif/4.gif",
  gif_5: url + "gif/5.gif",
  gif_6: url + "gif/6.gif",
  gif_7: url + "gif/7.gif",
  gif_8: url + "gif/8.gif",
  gif_9: url + "gif/9.gif",

  Server_Error: url + "Error.gif",
  Interview_Prep: url + "InterviewPrep.jpg",
  Refresh_icon: url + "Refresh_icon.png",
  crown: url + "gif/crown.gif",
  CompleteCVUpload: url + "CompleteCVUpload.png",
  CompleteInterviewpreparation: url + "CompleteInterviewpreparation.png",
  clipboard: url + "copy_clipboard.png",
  loader_icon: url + "loader-icon.png",
  Upload_cross: url + "Upload_cross.png",
  Attachment: url + "Attachment.png",
  copy_icon: url + "copy-icon.png",
  alert_icon: url + "alert-icon.png",
  check_icon_one: url + "check_icon.png",
  uncheck_icon: url + "uncheck_icon.png",
  notes_checkicon: url + "notes_checkicon.png",
  edit_note: url + "edit_note.png",
  step_one: url + "step-one.png",
  step_two: url + "step-two.png",
  step_three: url + "step-three.png",
  step_one_big: url + "step-one-big.png",
  white_close: url + "white_close.png",
  left_arrow: url + "left_arrow.png",
  UK: url + "Lang_UK.png",
  Italy: url + "Lang_Italy.png",
  Norway: url + "Lang_Norway.png",
  Sweden: url + "Lang_Sweden.png",
  success_img: url + "success_img.png",
  success_icon: url + "success_icon.png",
  alert_img: url + "alert_img.png",
  expire_alert_icon: url + "expire_alert_icon.png",
  watch: url + "watch.png",
  back_Arrow: url + "back_Arrow.png",
  Italian_img: url + "Italian_img.png",
  Sweden_img: url + "Sweden_img.png",
  uk_img: url + "uk_img.png",
  cancel_subscription_img: url + "cancel-subscription_img.png",
  smile_icon: url + "smile_icon.png",
  Happy_Announcement: url + "Happy_Announcement.png",
  gentaemen_img: url + "gentaemen_img.png",
  maintanence_img: url + "maintanence_img.png",
  maintanence_icon: url + "maintanence_icon.png",

  Gentleman_img: url + "Gentleman_img.png",
  Maintenance_img: url + "Maintenance_img.png",
  Maintenance_icon: url + "Maintenance_icon.png",
  billing: url + "billing.png",
  Gift: url + "Gift.png",
  checkbox_unselect: url + "checkbox_unselect.png",
  checkbox_select: url + "checkbox_select.png",
  arrow_right: url + "arrow_right.png",
  confirm_check: url + "confirm_check.png",
  subscription_icon: url + "subscription_icon.png",
  reedem_icon: url + "reedem_icon.png",
  billing_icon: url + "billing_icon.png",
  content_copy: url + "content_copy.png",
  email_icon: url + "email_icon.png",
  whatsapp_icon: url + "whatsapp_icon.png",
  undraw_winners: url + "undraw_winners.png",
  Visa: url + "Visa.png",
  reedem_icon_one: url + "reedem_icon_one.png",
  gift_icon: url + "gift_icon.png",
  no_data: url + "nodata.png",
  invalid_coupon: url + "invalid_coupon.png",
};
